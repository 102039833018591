<template>
  <Modal v-model="batchEditAssemblyInfoModal" class-name="batchEditAssemblyInfoStyle" :title="$t('key1000608')" :width="1000"
    :transfer="false" :mask-closable="false" @on-visible-change="batchEditAssemblyInfoChange">
    <!--tab栏切换-->
    <Tabs v-model="tabName" :animated="false" class="mb10">
      <TabPane v-for="item in tabList" :label="item.title" :name="item.value"></TabPane>
    </Tabs>
    <!--商品搜索-->
    <div class="container_box">
      <Form class="filter_box" ref="pageParams" :model="pageParams" :label-width="90" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="twoItemCol" :xl="twoItemCol" :lg="twoItemCol" :md="twoItemCol">
            <Form-item :label="$t('key1000765')" prop="searchValue">
              <Input :placeholder="placeholderTitle" v-model.trim="pageParams.searchValue"></Input>
            </Form-item>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--已选择的商品-->
    <div class="flex mb20" v-if="selectTableData.length>0">
      <h3 class="select_tag_title">{{ $t('key1000769') }}</h3>
      <Row type="flex" :gutter="gutterItem" class="tag_box_styles">
        <div class="tag_box_item" v-for="(item, index) in selectTableData" :key="index">
          <Tag closable color="blue" size="large" @on-close="deleteTag(item, index)">
            {{ tabName === 'mainMaterials' ? item.spu : item.sku }}
            <InputNumber
              :min="1"
              size="small"
              :max="99999"
              :formatter="value => `${parseInt(value)}`"
              v-model.trim="item.quantity"
              @on-change="quantityChange"
              class="ipt">
            </InputNumber>
          </Tag>
        </div>
      </Row>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="mainMaterialsDom"
        v-if="tabName === 'mainMaterials'"
        :update-show-children="true"
        row-key="treeId"
        highlight-row
        border
        max-height="450"
        :load-data="handleLoadData"
        :loading="tableLoading"
        :columns="mainMaterialsTableColumns"
        :data="mainMaterialsTableData">
        <!--主料-->
        <template #mainMaterialsKey="{row, index}">
          <Radio
            v-if="row.parentLevel"
            :true-value="1"
            :false-value="0"
            v-model="row.isMaster"
            @on-change="(val)=>mainMaterialsChange(val,row,index)">
          </Radio>
        </template>
      </Table>
      <Table
        v-else
        ref="selection"
        highlight-row
        border
        max-height="450"
        :loading="tableLoading"
        :columns="accessoriesTableColumns"
        @on-select-cancel="selectCancel"
        @on-select-all-cancel="selectAllCancel"
        @on-selection-change="tableChange"
        :data="accessoriesTableData">
      </Table>
      <!--分页区域-->
      <div class="flex align-items-center mt20" :class="tabName === 'accessories'? 'justify-content-between' : 'justify-content-end'">
        <div v-if="tabName === 'accessories'">{{ $t('key1000288') + selectTableData.length + $t('key1000768') }}</div>
        <Page
          v-if="!tableLoading"
          :total="total"
          @on-change="changePage"
          show-total
          :page-size="pageParams.pageSize"
          show-elevator
          :current="pageParams.pageNum"
          show-sizer
          @on-page-size-change="changePageSize"
          placement="top"
          :page-size-opts="pageArray">
        </Page>
      </div>
    </div>
    <template #footer>
      <Button @click="batchEditAssemblyInfoModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" :disabled="selectTableData.length <=0" @click="batchEditAssemblyInfoBtn">{{ $t('key1000096') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {commonSessionStorage} from "@/utils/common";

export default {
  name: 'batchEditAssemblyInfoModal',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      batchEditAssemblyInfoModal: false,
      tabName: 'mainMaterials',
      tabList: [
        {value: 'mainMaterials', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000801')},
        {value: 'accessories', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000802')}
      ],
      pageParams: {},
      total: 0,
      mainMaterialsTableData: [],
      mainMaterialsTableColumns: [
        {
          title: '',
          key: 'mainMaterials',
          align: 'center',
          width: 50,
          renderHeader: (h) => {
            return h('span', '');
          },
          slot: 'mainMaterialsKey'
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000782'),
          minWidth: 320,
          key: 'goodsInfo',
          align: 'left',
          tree: true,
          className: 'set_goodsInfo_box',
          render: (h, params) => {
            let imagePath = params.row.imagePath;
            let supplierSpu = params.row.spu;
            let name = params.row.name;
            let parentLevel = params.row.parentLevel;
            if (parentLevel) {
              let list = [
                v.tableImg(h, params, null, imagePath),
                h('div', {class: 'flex flex-direction ml10'}, [
                  h('div', [
                    h('Alink', {
                      props: {
                        linkName: name,
                        textAlign: 'left',
                        AlinkClass: 'ellipsis_2'
                      },
                      on: {
                        AlinkBtn: () => {
                          const {href} = v.$router.resolve({
                            path: '/productDetails',
                            query: {
                              type: 'look',
                              productId: params.row.productId
                            }
                          });
                          window.open(href, '_blank');
                        }
                      }
                    }),
                  ]),
                  h('p', {class: 'mt5'}, aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000785') + supplierSpu)
                ])
              ];
              return h('div', {class: 'flex ptb10'}, list);
            } else {
              let list = [];
              let supplierSku = params.row.sku;
              let productGoodsSpecificationList = params.row.productGoodsSpecifications || [];
              if (productGoodsSpecificationList.length > 0) {
                list = productGoodsSpecificationList.map((item) => {
                  return item.value
                });
              }
              return h('div', {class: 'wid100P text-align-right'}, [
                h('p', {class: 'mt2 mb2'}, supplierSku),
                h('p', {class: 'mt2 mb2 color-green'}, list.join(' • '))
              ])
            }
          }
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000787'),
          key: 'availableNumber',
          align: 'left',
          width: 120,
          render: (h, params) => {
            let parentLevel = params.row.parentLevel;
            if (parentLevel) {
              return h('span', v.priceTransform(params.row.wmsInventoryQuantity));
            } else {
              return h('span', v.priceTransform(params.row.availableNumber));
            }
          }
        }
      ],
      accessoriesTableColumns: [
        {
          type: 'selection',
          width: 70,
          align: 'center'
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000782'),
          minWidth: 320,
          key: 'goodsInfo',
          align: 'left',
          render: (h, params) => {
            let imagePath = params.row.imagePath;
            let supplierSpu = params.row.spu;
            let supplierSku = params.row.sku;
            let name = params.row.name;
            /*let productGoodsSpecifications = params.row.productGoodsSpecifications || [];*/
            /*let spec = productGoodsSpecifications.map(i => i.value).join('、') || '';*/
            let list = [
              v.tableImg(h, params, null, imagePath),
              h('div', {class: 'flex flex-direction ml10'}, [
                h('div', [
                  h('Alink', {
                    props: {
                      linkName: name,
                      textAlign: 'left',
                      AlinkClass: 'ellipsis_2'
                    },
                    on: {
                      AlinkBtn: () => {
                        const {href} = v.$router.resolve({
                          path: '/productDetails',
                          query: {
                            type: 'look',
                            productId: params.row.productId
                          }
                        });
                        window.open(href, '_blank');
                      }
                    }
                  }),
                ]),
                h('p', {class: 'mt5'}, aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000785') + supplierSpu),
                h('p', {class: 'mt5'}, aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000786') + supplierSku),
                /*h('p', {class: 'mt5'}, [
                  h('span', '规格：'),
                  h('span', {class: 'color-green'}, spec)
                ])*/
              ])
            ];
            return h('div', {class: 'flex ptb10'}, list);
          }
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000787'),
          key: 'availableNumber',
          align: 'left',
          width: 120,
          render: (h, params) => {
            return h('span', v.priceTransform(params.row.availableNumber));
          }
        }
      ],
      accessoriesTableData: [],
      selectTableData: [],
      ymsProductIdList: [],
    }
  },
  computed: {
    placeholderTitle() {
      return this.tabName === 'mainMaterials' ? aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000803') : aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000766');
    }
  },
  methods: {
    // 初始化数据
    initBatchEditAssemblyInfoData() {
      this.batchEditAssemblyInfoModal = true;
      this.search();
    },
    // 查询按钮
    search() {
      this.pageParams.pageNum = 1;
      this.getList();
    },
    // 重置按钮
    reset() {
      this.$refs['pageParams'].resetFields();
      this.search();
    },
    // 获取列表数据
    getList() {
      let v = this;
      v.tableLoading = true;
      v.total = 0;
      v.accessoriesTableData = [];
      v.mainMaterialsTableData = [];
      v.optionBtnWidth = [];
      let url;
      let params = Object.assign({}, v.pageParams);
      if (v.tabName === 'mainMaterials') {
        url = api.post_productInfo_querySpu;
      } else {
        url = api.post_productGoods_query;
      }
      v.axios.post(url, params).then(response => {
        console.log('--------response---------', response)
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.total = data.total;
          if (v.tabName === 'accessories') {
            v.accessoriesTableData = data.list || [];
            v.recordSelectGoods(v.accessoriesTableData);
          } else {
            let newList = data.list || [];
            v.mainMaterialsTableData = newList.map(item => {
              let {productGoods, ...obj} = item;
              return {
                ...obj,
                productGoods,
                quantity: 1,
                isMaster: 0,
                parentLevel: true,
                treeId: 'parentLevel_' + item.productId,
                _loading: false,
                _showChildren: false,
                children: []
              };
            });
            if (v.selectTableData.length > 0) {
              v.mainMaterialsTableData.map((item) => {
                v.selectTableData.map((ele) => {
                  if (item.spu === ele.spu) {
                    item.isMaster = 1;
                  }
                })
              });
              v.$forceUpdate();
            }
          }
        }
      });
    },
    // 跨页记录已勾选的商品
    recordSelectGoods(data) {
      let v = this;
      let list = [...v.selectTableData, ...v.ymsProductIdList];
      if (data && data.length > 0) {
        v.selectTableData = v.uniqueFunc(list, 'productGoodsId');
        data.map((item, index) => {
          v.selectTableData.map((ele) => {
            if (item.productGoodsId === ele.productGoodsId) {
              v.$set(data[index], '_checked', true);
            }
          })
        });
        v.$forceUpdate();
      }
    },
    // 确定按钮
    batchEditAssemblyInfoBtn() {
      // 选择的主料数据
      let mainMaterialsData = commonSessionStorage.getItem('selectMainMaterials') || [];
      // 选择的辅料数据
      let accessoriesData = commonSessionStorage.getItem('selectAccessories') || [];
      mainMaterialsData.map((item) => {
        item.accessoriesList = accessoriesData;
      });
      if (mainMaterialsData.length > 0) {
        let mainMaterialsTalg = mainMaterialsData.every((ele) => {
          return ele.quantity > 0
        });
        if (mainMaterialsTalg) {
          if (accessoriesData.length > 0) {
            let accessoriesTalg = accessoriesData.every((ele) => {
              return ele.quantity > 0
            });
            if (!accessoriesTalg) {
              this.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000804'));
              return false;
            }
          }
          this.$emit('updateBatchEditAssemblyInfo', mainMaterialsData);
          this.batchEditAssemblyInfoModal = false;
        } else {
          this.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000805'));
          return false;
        }
      } else {
        this.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000806'));
        return false;
      }
    },
    // 监听弹窗
    batchEditAssemblyInfoChange(val) {
      setTimeout(() => {
        commonSessionStorage.removeItem('selectMainMaterials');
        commonSessionStorage.removeItem('selectAccessories');
      }, 2000);
      this.$refs['pageParams'].resetFields();
      this.selectTableData = [];
      this.ymsProductIdList = [];
      this.tabName = 'mainMaterials';
      if (val) {
        this.stopScroll();
      } else {
        this.removeStopScroll();
      }
    },
    // 删掉已经选中的sku
    deleteTag(item, index) {
      let v = this;
      v.selectTableData.splice(index, 1);
      if (v.tabName === 'mainMaterials') {
        v.mainMaterialsTableData.map((ele) => {
          ele.isMaster = 0;
        });
        commonSessionStorage.removeItem('selectMainMaterials');
      } else {
        v.accessoriesTableData.map((ele, idx) => {
          if (ele.productGoodsId === item.productGoodsId) {
            v.$refs['selection'].$refs.tbody.objData[idx]._isChecked = false;
          }
        });
        commonSessionStorage.setItem('selectAccessories', v.selectTableData);
      }
      v.$forceUpdate();
    },
    // 取消选中的数据
    selectCancel(selection, row) {
      let v = this;
      let productGoodsId = row.productGoodsId;
      v.selectTableData.map((item, index) => {
        if (item.productGoodsId === productGoodsId) {
          v.selectTableData.splice(index, 1);
        }
      });
      commonSessionStorage.setItem('selectAccessories', v.selectTableData);
    },
    // 全部取消选中的数据
    selectAllCancel(selection) {
      if (selection.length <= 0) {
        let productGoodsIds = this.accessoriesTableData.map((ele) => {
          return ele.productGoodsId;
        });
        if (productGoodsIds.length > 0) {
          this.selectTableData = this.selectTableData.filter((item) => {
            if (!productGoodsIds.includes(item.productGoodsId)) {
              return item;
            }
          });
        }
        commonSessionStorage.setItem('selectAccessories', this.selectTableData);
      }
    },
    // 勾选列表数据
    tableChange(data) {
      this.ymsProductIdList = [];
      if (data.length > 0) {
        this.ymsProductIdList = data.map(i => {
          if (!i.quantity) {
            i.quantity = 1;
          }
          return i;
        });
      }
    },
    // 设为主料
    mainMaterialsChange(val, item, index) {
      let v = this;
      v.mainMaterialsTableData.map((item) => {
        item.isMaster = 0;
      });
      v.selectTableData = [item];
      commonSessionStorage.setItem('selectMainMaterials', this.selectTableData);
      setTimeout(() => {
        v.$set(this.mainMaterialsTableData[index], 'isMaster', 1);
      }, 20);
      v.$forceUpdate();
    },
    // 改变数量
    quantityChange() {
      if (this.tabName === 'mainMaterials') {
        commonSessionStorage.setItem('selectMainMaterials', this.selectTableData);
      } else {
        commonSessionStorage.setItem('selectAccessories', this.selectTableData);
      }
    },
    // 异步请求获取spu下所有的sku
    handleLoadData(item, callback) {
      let v = this;
      let originalItem = JSON.parse(JSON.stringify(item));
      if (originalItem.children.length <= 0) {
        v.axios.get(api.get_productInfo + originalItem.productId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              let productGoodsList = data.productGoodsList || [];
              originalItem._loading = false;
              if (productGoodsList.length > 0) {
                productGoodsList.map((ele) => {
                  ele.parentLevel = false;
                  ele.treeId = 'childLevel_' + ele.productGoodsId
                });
                callback(productGoodsList);
                v.$forceUpdate();
              }
            }
          }
        });
      } else {
        callback();
      }
    },
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    },
    // 监听Tab切换
    tabName: {
      handler(val) {
        if (this.batchEditAssemblyInfoModal) {
          let mainMaterialsObj = {
            cnName: null,
            orderBy: "createdTime",
            upDown: "down",
            pageSize: 15,
            pageNum: 1,
            ymsProductCategoryId: null,
            ymsSpuList: [],
            skus: [],
            ymsSkus: [],
            spuList: [],
            auditStatus: null,
            shelveStatus: null,
            searchValue: null
          };
          let accessoriesObj = {
            pageSize: 15,
            pageNum: 1,
            searchValue: null,
            productTypes: [1, 2],
            warehouseId: this.getWarehouseId()
          };
          if (val === 'mainMaterials') {
            this.selectTableData = commonSessionStorage.getItem('selectMainMaterials') || [];
            this.pageParams = mainMaterialsObj;
          } else {
            this.selectTableData = commonSessionStorage.getItem('selectAccessories') || [];
            this.pageParams = accessoriesObj;
          }
          this.getList();
          this.$forceUpdate();
        }
      },
      immediate: true,
      deep: true
    },
    // 监听选中的数据
    ymsProductIdList: {
      handler(data) {
        let v = this;
        if (data.length > 0) {
          let list = [...v.selectTableData, ...data];
          v.selectTableData = this.uniqueFunc(list, 'productGoodsId');
          commonSessionStorage.setItem('selectAccessories', v.selectTableData);
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="less">
.batchEditAssemblyInfoStyle {
  .tag_box_styles {
    flex: 1;
    padding: 0 8px;
    max-height: 150px;
    overflow-y: auto;
    box-sizing: border-box;

    .ivu-icon-ios-close {
      color: #2D8CF0 !important;
    }

    .tag_box_item {
      margin: 0 10px 10px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .ipt {
        width: 75px;
        margin: 0 5px 3px 5px;
      }
    }
  }

  .table_box {
    .set_goodsInfo_box {
      .ivu-table-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .productInfo_content {
          order: 1;
        }

        .ivu-table-cell-tree {
          order: 2;
        }
      }
    }
  }
}
</style>
