<template>
  <div>
    <Modal v-model="addMultipleImagesModal" :title="$t('key1000311')" width="1000" class-name="addMultipleImagesStyle"
      :mask-closable="false" @on-visible-change="addMultipleImagesChange">
      <h2 class="font-size-18 font-weight-bold color-333">{{ $t('key1000312') }}</h2>
      <p class="font-size-14 mt10">{{ $t('key1000313') }}</p>
      <p class="font-size-12 color-999">
        1. {{ $t('key1000314') }}<br/>
        2. {{ $t('key1000315') }}<br/>
        3. {{ $t('key1000316') }}<br/>
        {{ $t('key1000317') }}
      </p>
      <div class="imgagesList_box">
        <div class="imgagesList_box_item" v-for="(path,index) in cropImageList">
          <div class="img_content" @click="clickOnCropBtn(path, index)">
            <span class="clickOnCrop_box">{{ $t('key1000318') }}</span>
            <img class="img-styles" :src="setImgPath(path, null, null, null, false)" @error="setErrorImg($event)" alt=""/>
          </div>
          <div class="flex align-items-center justify-content-center cursor" @click="deleteImgBtn(index)">
            <Icon type="ios-trash-outline" size="18"/>
            <span class="font-size-12 themeColor ml3" style="position: relative; top: 2px;">{{ $t('key1000319') }}</span>
          </div>
        </div>
        <Upload
          name="files"
          :headers="headObj"
          :show-upload-list="false"
          :on-success="uploadFilesProductImgSuccess"
          :on-error="uploadFilesError"
          :before-upload="beforeHandleUpload"
          multiple
          :action="uploadFilesUrl"
          type="drag"
          style="display: inline-block;width:100px;">
          <div class="upload-box">
            <i class="iconfont upload-icon">&#xe68d;</i>
            <p>{{ $t('key1000320') }}</p>
          </div>
        </Upload>
      </div>
      <template #footer>
        <Button @click="addMultipleImagesModal=false">{{ $t('key1000097') }}</Button>
        <Button type="primary" :disabled="cropImageList.length <=0" @click="addMultipleImagesBtn">{{ $t('key1000321') }}</Button>
      </template>
    </Modal>
    <!--裁剪图片的弹窗-->
    <kjnovaClipperModal :multiple="true" ref="kjnovaClipper" @updateUploadImg="updateUploadImg"></kjnovaClipperModal>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import FileType from "file-type";
import kjnovaClipperModal from "@/components/common/kjnovaClipperModal.vue";
import {endLoading, startLoading} from "@/utils/loading";

export default {
  name: 'addMultipleImagesModal',
  mixins: [Mixin],
  props: {
    productType: {
      type: Number
    }
  },
  data() {
    return {
      addMultipleImagesModal: false,
      cropImageList: [],
      beforeHandleUploadImagesList: [],
      recordAndUploadImagesList: [],
      currentIndex: 0,
    }
  },
  computed: {
    uploadFilesUrl() {
      return api.post_uploadFiles + '?basePath=yms-supplier-service&&action="uploadimage"';
    }
  },
  methods: {
    // 初始化数据
    initAddMultipleImagesData(imgagesList) {
      this.cropImageList = imgagesList;
      this.addMultipleImagesModal = true;
    },
    // 确定上传
    addMultipleImagesBtn() {
      let v = this;
      let maxSize = 6000;
      let minSize = 800;
      let imageDimensions = [];
      let promises = v.cropImageList.map((path) => {
        return new Promise((resolve, reject) => {
          let img = new Image();
          img.onload = () => {
            imageDimensions.push({
              width: img.width,
              height: img.height,
              imgUrl: path
            });
            resolve();
          };
          img.onerror = () => {
            reject(new Error(`${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000322')} ${path}`));
          };
          img.src = v.setImgPath(path, null, null, null, false);
        });
      });

      Promise.all(promises).then(() => {
        let imgList = imageDimensions.map((item) => item.imgUrl);
        if (imgList.length > 0) {
          v.$emit('updateUploadImgList', imgList);
          v.addMultipleImagesModal = false;
        }
        /*if (v.productType === 2) {
          let imgList = imageDimensions.map((item) => item.imgUrl);
          if (imgList.length > 0) {
            v.$emit('updateUploadImgList', imgList);
            v.addMultipleImagesModal = false;
          }
          return;
        }*/


        /*let successImagesList = imageDimensions.filter((item) => {
          return item.width >= minSize && item.height >= minSize && item.width <= maxSize && item.height <= maxSize;
        });
        let failImagesList = imageDimensions.filter((item) => {
          return item.width < minSize || item.height < minSize || item.width > maxSize || item.height > maxSize;
        });
        if (failImagesList.length <= 0) {
          v.$Message.success(`已成功上传${successImagesList.length}张图片`);
        } else {
          let type = successImagesList.length <= 0 ? 'error' : 'warning';
          v.$Message[type]({
            content: `已成功上传${successImagesList.length}张图片，上传失败${failImagesList.length}张图片, 失败原因：图片尺寸<=${minSize}且<=${maxSize}`,
            duration: 8,
            closable: true
          });
        }
        if (successImagesList.length > 0) {
          let imgList = successImagesList.map((item) => item.imgUrl);
          if (imgList.length > 0) {
            v.$emit('updateUploadImgList', imgList);
            v.addMultipleImagesModal = false;
          }
        }*/
      }).catch((error) => {
        console.error('======error====', error);
      });
    },
    // 监听弹窗
    addMultipleImagesChange(value) {
      if (value) {
        this.stopScroll();
      } else {
        this.removeStopScroll();
        this.cropImageList = [];
      }
    },
    // 校验轮播图图片大小和格式
    beforeHandleUpload(file) {
      return new Promise((resolve, reject) => {
        // 校验图片大小不能大于5M
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
          this.$Message.error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000323'));
          return reject();
        }

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
          const buffer = Buffer.from(reader.result.split(',')[1], 'base64')
          const {mime} = await FileType.fromBuffer(buffer)
          if (!this.imageFormatList.includes(mime)) {
            this.$Message.error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000324'));
            return reject()
          }
          this.beforeHandleUploadImagesList.push(file);
          resolve()
        }
      })
    },
    // 上传图片成功
    uploadFilesProductImgSuccess(response, file, fileList) {
      let v = this;
      startLoading(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000325'));
      if (response.code === 0) {
        let url = response.datas[0];
        v.recordAndUploadImagesList.push(url);
        if (v.beforeHandleUploadImagesList.length === v.recordAndUploadImagesList.length) {
          endLoading();
          v.cropImageList = [...v.cropImageList, ...v.recordAndUploadImagesList];
          setTimeout(() => {
            v.beforeHandleUploadImagesList = [];
            v.recordAndUploadImagesList = [];
          }, 200);
        }
      }
    },
    // 点击裁剪
    clickOnCropBtn(path, index) {
      this.currentIndex = index;
      let imgUrl = this.setImgPath(path, null, null, null, false)
      this.$refs['kjnovaClipper'].initKjnovaClipperData(imgUrl);
    },
    // 删除图片
    deleteImgBtn(index) {
      this.cropImageList.splice(index, 1);
      this.$forceUpdate();
    },
    // 更新裁剪图片
    updateUploadImg(data) {
      this.cropImageList.map((path, index) => {
        if (index === this.currentIndex) {
          this.cropImageList.splice(index, 1, data);
        }
      });
      this.$forceUpdate();
    }
  },
  components: {
    kjnovaClipperModal
  }
};
</script>

<style lang="less">
.addMultipleImagesStyle {
  .ivu-modal-body {
    max-height: 600px;
    overflow-y: auto;
  }

  .imgagesList_box {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .imgagesList_box_item {
      margin: 0 18px 24px 0;

      .img_content {
        position: relative;
        cursor: pointer;

        .clickOnCrop_box {
          width: 100%;
          height: 20px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          text-align: center;
          font-size: 13px;
          color: #fff;
          background-color: #ada9a9;
          display: none;
        }

        &:hover {
          .clickOnCrop_box {
            display: inline-block;
          }
        }
      }

      .img-styles {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 1px solid #ddd;
      }
    }

    .upload-box {
      width: 100px;
      height: 100px;
      color: #999999;
      transition: color 0.1s ease-in;
      background: #fff;
      border: 1px dashed #dcdee2;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .upload-icon {
        font-size: 40px;
        display: block;
        margin-bottom: 10px;
      }

      &:hover {
        color: #666666;
      }
    }
  }
}
</style>
